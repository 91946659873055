<template>
  <div id="contrated-list">
    <v-card>
      <v-card-title>見積済(一覧)</v-card-title>
      <v-row class="px-2 ma-0">
        <v-col
          cols="12"
          md="2"
        >
          <v-autocomplete
            v-model="customer_id"
            placeholder="取引先"
            :items="customerIds"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-autocomplete
            v-model="pellet_id"
            placeholder="Pellet"
            :items="pelletIds"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col>

        <v-col
          cols="12"
          md="2"
        >
          <v-autocomplete
            v-model="quot_stat"
            :items="quotStatusMaster"
            placeholder="見積状態"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-menu
            v-model="dateStartMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="startDate"
                placeholder="From"
                dense
                hide-details
                outlined
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startDate"
              @input="dateStartMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-menu
            v-model="dateEndMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="endDate"
                placeholder="To"
                dense
                outlined
                hide-details
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              @input="dateEndMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-divider class="mt-4"></v-divider>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-text-field
          v-model="searchQuery"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
        >
        </v-text-field>
        <v-spacer></v-spacer>
        <div class="d-flex align-center flex-wrap">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="mb-4 me-3" color="primary" dark v-bind="attrs" v-on="on">
                <v-icon size="17" class="me-1">
                  {{ icons.mdiPlaylistEdit }}
                </v-icon>
                <span>一括操作</span>
              </v-btn>
            </template>
            <v-list>
              <v-list-item :disabled="!bCanContract" @click="openContractDialog">
                <v-list-item-icon class="pr-1 ma-auto">
                  <v-icon :disabled="bCanContract">
                    {{ icons.mdiFileCheckOutline }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content class="d-flex align-center">
                  <v-list-item-title>契約締結</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :disabled="!bCanCancel" @click="openCancelDialog">
                <v-list-item-icon class="pr-1 ma-auto">
                  <v-icon :disabled="!bCanCancel">
                    {{ icons.mdiFileSendOutline }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content class="d-flex align-center">
                  <v-list-item-title>契約取消</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn
            color="secondary"
            outlined
            class="mb-4"
            @click="downloadCSV"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn>
        </div>
      </v-card-text>
      <v-data-table
        ref="contracts"
        v-model="selectedQuotations"
        class="class-on-data-table elevation-1"
        :headers="headers"
        :items="quotationList"
        item-key="quot_id"
        show-select
        :search="searchQuery"
      >
        <template v-slot:[`item.quot_id`]="{ item }">
          <a
            href="javascript:void(0);"
            @click="pushToResvBid(item)"
          >{{ item.quot_id }}</a>
        </template>

        <template v-slot:[`item.cust_abbr`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <a
                v-bind="attrs"
                :style="'text-decoration: underline; color:#666666' "
                v-on="on"
              >{{ item.cust_abbr }}</a>
            </template>
            <span>{{ item.cust_id }}</span><br>
            <span>{{ item.cust_name }}</span><br>
            <span>{{ item.cust_name_eng }}</span><br>
          </v-tooltip>
        </template>

        <template v-slot:[`item.quot_weight`]="{ item }">
          <label>{{
            item.quot_weight ? item.quot_weight.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,') : '0'
          }}</label>
        </template>
        <template v-slot:[`item.quot_f_amount`]="{ item }">
          {{ formatAmount(item.ccy_mark,item.quot_f_amount) }}
        </template>
        <template v-slot:[`item.quot_code`]="{ item }">
          <v-icon v-if="item.quot_code !== null" class="mr-2" @click="previewItem(item)">
            {{ icons.mdiFilePdfBox }}
          </v-icon>
        </template>

        <template v-slot:[`body.append`]>
          <tr v-if="product_id">
            <td
              class="font-weight-bold"
              :colspan="5"
              style="background: #fafafa"
            >
              <label>{{ product_id }}の合計</label>
            </td>
            <td
              class="font-weight-bold"
              style="text-align: right; background: #fafafa"
            >
              <label>{{ sumWgt }}</label>
            </td>
            <td
              style="background: #fafafa"
              :colspan="3"
            ></td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <ContractConfirm
      :dialog="showContract"
      :selected-quotations="selectedQuotations"
      @closeForm="closeConfirmDialog"
    >
    </ContractConfirm>
  </div>
</template>

<script>
/* eslint-disable */
import {
  mdiPencil,
  mdiDelete,
  mdiFileSendOutline,
  mdiFileCheckOutline,
  mdiExportVariant,
  mdiFilePdfBox,
  mdiCalendar,
  mdiMinus,
  mdiPlus
} from '@mdi/js'
import { mapActions, mapMutations, mapState } from 'vuex'

import ContractConfirm from './ContractConfirm.vue'

/* eslint-disable */
export default {
  components: {
    ContractConfirm,
  },
  data: () => ({
    icons: {
      mdiPencil,
      mdiDelete,
      mdiExportVariant,
      mdiFileSendOutline,
      mdiFileCheckOutline,
      mdiCalendar,
      mdiFilePdfBox,
      mdiMinus,
      mdiPlus,
    },
    dateEndMenu: false,
    dateStartMenu: false,

    quot_stat: '',
    bCanContract: false,
    bCanCancel: false,
    showContract: false,
    searchBar: {},
    searchQuery: '',
    pellet_id: '',
    contract_id: '',
    customer_id: '',
    startDate: '',
    preorder_id:'',
    auction_id:'',
    endDate: '',
    isExpand: false,
    OperationDisabled: true,
    selectedQuotations: [],
    headers: [
      {
        text: 'ID',
        value: 'quot_id',
        align: 'left',
        width: '8%',
        fixed: true,
      },

      {
        text: 'Pellet',
        value: 'pellet_id',
        align: 'left',
        width: '10%',
        fixed: true,
      },
      {
        text: '商品コード',
        value: 'item_id',
        align: 'left',
        width: '15%',
        fixed: true,
      },
      {
        text: '取引先',
        value: 'cust_abbr',
        align: 'left',
        width: '12%',
        fixed: true,
      },

      // {
      //   text: '見積日時',
      //   value: 'quot_dt',
      //   align: 'left',
      //   width: '10%',
      //   fixed: true,
      // },
{
        text: '予約入札ID',
        value: 'r_b_id',
        align: 'left',
        sortable: true,
        width: '10%',
        fixed: true,
      },
      {
        text: '重量(kg)',
        value: 'quot_weight',
        align: 'right',
        width: '10%',
        sortable: true,
        fixed: true,
      },
      {
        text: '見積金額',
        value: 'quot_f_amount',
        align: 'right',
        width: '12%',
        sortable: true,
        fixed: true,
      },
      {
        text: 'PDF',
        value: 'quot_code',
        sortable: false,
        align: 'center',
        width: '11%',
        fixed: true,
      },
      {
        text: '状態',
        value: 'quot_stat_desc',
        sortable: false,
        align: 'center',
        width: '10%',
        fixed: true,
      },
      // {
      //   text: '備考',
      //   value: 'contract_note',
      //   sortable: false,
      //   align: 'left',
      //   width: '17%',
      //   fixed: true,
      // },
      {
        text: '　',
        value: 'actions',
        sortable: false,
        align: 'center',
        width: '10%',
        fixed: true,
      },
    ],
  }),

  computed: {
    ...mapState('pelletStore', ['pelletIds']),
    ...mapState('customerStore', ['customerIds']),
    ...mapState('quotationStore', ['quotationList','quotStatusMaster']),
    ...mapState('auctionStore', ['auctionIds']),
    ...mapState('preorderStore', ['preorderIds']),




    formatAmount() {
      return function (ccyMark, amount) {

        // if (ccyMark === undefined || ccyMark === null) return ''
        // if (amount === undefined || amount === null) return ''

        // return ccyMark + amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')

        if (typeof amount === 'number') {
          return `${ccyMark ?? ''}${amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
        }
        if (typeof amount === 'string') {
          return `${ccyMark ?? ''}${parseFloat(amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
        }

        return ''
      }
    },

  },
  watch: {
    selectedQuotations(vals) {
      if (vals.length === 0) {
        this.bCanContract = false
        this.bCanCancel = false
        return
      }

      this.bCanContract = true

      for (let i = 0; i < vals.length; i += 1) {
        if (vals[i].quot_stat !== 3 ) {
          this.bCanContract = false
          break
        }

      }
    },

    pellet_id(newValue) {
      this.changeRouteQuery('pellet_id', newValue)
      this.loadData()
    },

    customer_id(newValue) {
      this.changeRouteQuery('cust_id', newValue)
      this.loadData()
    },

    quot_stat(newValue) {
      this.changeRouteQuery('quot_stat', newValue)
      this.loadData()
    },

    startDate(newValue) {
      this.changeRouteQuery('startDate', newValue)
      if (this.product_id) {
        this.loadData(true)
      } else {
        this.loadData(false)
      }
    },
    endDate(newValue) {
      this.changeRouteQuery('endDate', newValue)

        this.loadData()

    },
  },
  created() {
    console.log("created")
    this.loadQuery()
    this.changeRouteQuery()
    // this.loadContractIds()
    // this.loadProductIds()
    // this.loadCustomerIds()
     this.loadData()
  },

  methods: {
    ...mapActions('preorderStore', ['loadPreorders']),
    ...mapActions('customerStore', ['loadCustomerIds']),
    ...mapActions('quotationStore', ['loadQuotations']),
    ...mapActions('auctionStore', ['loadAuctions']),
    ...mapActions('pelletStore', ['loadPelletIds']),
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('quotationStore', ['loadQuotationStatus']),

    getResvBidID(item) {


        if (item.resv_id !== null) {
          return 'R-'+ item.resv_id.toString()
        }

        return 'B-'+ item.bid_id.toString()


    },

    openConfirmDialog()
    {
      this.showContract = false
      this.showContract = true

    },
    openCancelDialog()
    {
      this.showContract = false
      this.showContract = true

    },

    closeConfirmDialog()
    {
      this.selectedQuotations =[]
      this.showContract = false
      this.loadData()
    },


    //入札実績OR予約実績実績に遷移する
    pushToResvBid(item)
    {
      if (item.resv_id!==null) {
        //予約実績に遷移する
        this.$router.push({
          name: 'reserved-progress',
          params: {
            id: item.resv_id,
            from:9,
          },
        })
      }
      else {
        this.$router.push({
          name: 'bidding-progress',
          params: {
            id: item.bid_id,
            from:9,
          },
        })
      }
    },


    previewItem(item)
    {
      console.log('view', item)
      this.$router.push({
        name: 'quotation-preivew',
        params: {
          id: item.quot_id,
        },
      })
    },

    openOperationDialog() {
      this.dialog = true
    },

    loadData() {
      this.setOverlayStatus(true)
      Promise.all([
        this.loadCustomerIds(),
        this.loadPelletIds(),
        this.loadQuotationStatus(),
        // this.loadPreorders(),
        // this.loadAuctions(),

        this.loadQuotations(this.$route.query),
      ]).then(() => {

      }).catch(error => {
        console.log('loadData', error)
      }).finally(() => {
        this.setOverlayStatus(false)
      })

    },

    changeRouteQuery(key, val) {
      const query = JSON.parse(JSON.stringify(this.$route.query))
      if (val) {
        query[key] = val
      } else {
        delete query[key]
      }
      this.$router.push({ path: this.$route.path, query })
    },
    loadQuery() {
      this.contract_id = this.$route.query.contract_id ?? ''
      this.product_id = this.$route.query.product_id ?? ''
      this.customer_id = this.$route.query.customer_id ?? ''
      this.contract_status = this.$route.query.contract_status ?? ''
      this.startDate = this.$route.query.startDate ?? ''
      this.endDate = this.$route.query.endDate ?? ''
    },

    //契約確認画面を表示する
    openContractDialog()
    {
      this.showContract = true
    },

    //契約を取消にする
    openCancelDialog()
    {

    },

    downloadCSV() {

      // eslint-disable-next-line no-useless-concat
      let csv = '\ufeff' + 'No.,契約ID,契約日,取引先ID,契約名,ペレットID,重量(kg),契約単価,備考\n'
      let no = 0
      this.contractList.forEach(el => {
        no += 1
        const contract_id = "CP-" + el.contract_id +"-" + el.detail_id
        const contract_date = el.contract_date ?? ""
        const customer_id = el.customer_id ?? ""
        const contract_name = el.contract_name ?? ""
        const product_id = el.product_id ?? ""
        const contract_weight = el.contract_weight ? el.contract_weight.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') : ""
        const contract_price = el.contract_price ? el.contract_price.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') : ""
        const note  = el.contract_note ?? ""
        const line = `"${no}","${contract_id}","${contract_date}","${customer_id}","${contract_name}","${product_id}","${contract_weight}","${contract_price}","${note}"\n`
        csv += line
      })
      const blob = new Blob([csv], { type: 'text/csv' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      const nowstr = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substring(0, 19)
      const newstr = nowstr.replace(/(-|:|T)/g, '')
      link.download = `ペレット契約一覧-${newstr}.csv`
      link.click()
    },
  },
}
</script>
<style scoped>
.table-filed {
  white-space: normal;
}
.text-format {
  text-align: right !important;
}
tr {
  height: 50px;
  font-size: 14px;
}
td {
  border-bottom: thin solid rgba(94, 86, 105, 0.14);
  padding: 0px 16px;
}
</style>
